import React from "react";
import Image from "next/image";
import Link from "next/link";

export default function CustomError() {
  return (
    <>
      <div className="bg-[#21252A] w-full fixed top-0">
        <div className="flex-1 flex justify-center items-center py-2 lg:my-0">
          <Link href="/">
            <Image src="/images/Logo-white.png" alt="Snacktime" />
          </Link>
        </div>
      </div>
      <div>
        <div className="w-full h-screen flex items-center justify-center ">
          <div className="mx-auto w-96">
            <span className="text-2xl flex items-center text-center">
              Sorry this page could not be found. Try refreshing the page.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
