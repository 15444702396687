// This is a direct invocation of the @apollo/client package instead of the aws provided appsync
//  abstraction (aws-appsync package).
// Does not currently support offline mode.
// See comment here: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448#issuecomment-541229659
// See docs here: https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { InMemoryCache, ApolloClient, ApolloLink } from "@apollo/client";
import { RetryLink } from "apollo-link-retry";
import { Auth } from "aws-amplify";

// eslint-disable-next-line default-param-last
const combine = (existing, incoming, { readField, mergeObjects }) => {
  const merged = existing ? existing.slice(0) : [];
  const newItems = [];
  const existingIdToIndex = {};
  const newIdToIndex = {};
  existing?.forEach((item, index) => {
    existingIdToIndex[readField("id", item)] = index;
  });
  incoming?.forEach((item) => {
    const id = readField("id", item);
    const existingIndex = existingIdToIndex[id];
    const newIndex = newIdToIndex[id];
    if (typeof existingIndex === "number") {
      // Merge the new item data with the existing item data.
      merged[existingIndex] = mergeObjects(merged[existingIndex], item);
    } else if (typeof newIndex === "number") {
      // Merge the new item data with the new item data.
      newItems[newIndex] = mergeObjects(newItems[newIndex], item);
    } else {
      // First time we've seen this item in this array.
      newIdToIndex[id] = newItems.length;
      newItems.push(item);
    }
  });
  merged.push(...newItems);
  return merged;
};

const merge = (existing, incoming, options) => {
  // The existance of cursors signals this is a pagination request
  const isPagination = !!options.variables?.cursors;
  if (isPagination === false) {
    // this is an initial fetch or a refetch, just return incoming
    return incoming;
  }
  return combine(existing, incoming, options);
};

const cacheConfig = {
  typePolicies: {
    FeedConnection: {
      fields: {
        posts: {
          merge,
          keyArgs: [
            "feedType",
            "hashtagId",
            "targetUserId",
            "targetUsername",
            "contentType",
          ],
        },
      },
    },
    UsersConnection: {
      fields: {
        users: {
          merge,
          keyArgs: (args, { variables }) => {
            const keyArgs = [];
            ["alertId", "targetUserId"].map((key) => {
              if (key in variables) {
                keyArgs.push(key);
              }
              return key;
            });
            return keyArgs;
          },
        },
      },
    },
    CommentsConnection: {
      fields: {
        comments: {
          merge,
          keyArgs: ["postId", "commentId", "commentIds"],
        },
      },
    },
    Comment: {
      fields: {
        replies: {
          merge: combine,
        },
      },
    },
    AlertsConnection: {
      fields: {
        alerts: {
          merge,
        },
      },
    },
    SearchConnection: {
      fields: {
        users: { merge, keyArgs: ["keywords"] },
        posts: { merge, keyArgs: ["keywords"] },
        hashtags: { merge, keyArgs: ["keywords"] },
      },
    },
    PostWindowsConnection: {
      fields: {
        postWindows: {
          merge,
          keyArgs: ["targetUserId", "targetUsername"],
        },
      },
    },
  },
};

const authLinkData = {
  url: process.env.NEXT_PUBLIC_APPSYNC_UNAUTHENTICATED_ENDPOINT,
  region: process.env.NEXT_PUBLIC_APPSYNC_REGION,
  auth: {
    type: process.env.NEXT_PUBLIC_APPSYNC_UNAUTHENTICATED_AUTHENTICATION_TYPE,
    // TODO: Can we avoid the arrow function here?
    credentials: () => Auth.currentCredentials(),
  },
};

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const authenticatedLink = ApolloLink.from([
  retryLink,
  createAuthLink(authLinkData),
  createSubscriptionHandshakeLink(authLinkData),
]);

const cache = new InMemoryCache(cacheConfig);

export default new ApolloClient({
  link: authenticatedLink,
  cache,
  ssrMode: true,
});
