import React, { useEffect, useCallback } from "react";
import NProgress from "nprogress";
import { ApolloProvider } from "@apollo/client";
import Router from "next/router";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import client from "@graphql/apollo-client";
import { AmplifyConfigure } from "@utility/amplify";
import CustomError from "@components/errorPages/CustomError";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles.css";
import "nprogress/nprogress.css";
import CookieConsentComponent from "@components/CookieBanner";

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: false,
});

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function Snacktime({ Component, pageProps }) {
  const initApp = useCallback(() => {
    AmplifyConfigure();
  }, []);

  useEffect(() => {
    initApp();

    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, [initApp]);

  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={CustomError}>
        <ApolloProvider client={client}>
          <Component {...pageProps} client={client} />
          <CookieConsentComponent />
        </ApolloProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default Snacktime;
