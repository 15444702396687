import { Amplify } from "aws-amplify";

export const AmplifyConfigure = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
      region: process.env.NEXT_PUBLIC_COGNITO_REGION,
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_WEB_CLIENT_ID,
    },
    Analytics: {
      AWSPinpoint: {
        appId: process.env.NEXT_PUBLIC_PINPOINT_APP_ID,
        region: process.env.NEXT_PUBLIC_PINPOINT_REGION,
        resendLimit: 5,
      },
    },
  });
};
