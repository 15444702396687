import React from "react";
import CookieConsent from "react-cookie-consent";
import { useRouter } from "next/router";

export default function CookieBanner() {
  const router = useRouter();
  if (router.route === "/auth" || router.route === "/p/[postId]") {
    return null;
  }
  return (
    <CookieConsent>
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
}
